import React from 'react';
import Moment from "react-moment";
import 'moment/locale/nl';

const getPercentage = (percentage) => {

    var i = Math.max(0, Math.min(360, ((360 / 100) * percentage)));
    return (100 * i) / 360;
}

const getPercentageStyles = (percentage) => {

    var i       = Math.max(0, Math.min(360, ((360 / 100) * percentage)));
    var bgStyle = '';

    if (i <= 180) {
        bgStyle = 'linear-gradient(' + (90 + i) + 'deg, transparent 50%, #A2ECFB 50%),linear-gradient(90deg, #A2ECFB 50%, transparent 50%)';
    } else {
        bgStyle = 'linear-gradient(' + (i - 90) + 'deg, transparent 50%, #39B4CC 50%),linear-gradient(90deg, #A2ECFB 50%, transparent 50%)';
    }

    return {
        backgroundImage: bgStyle
    }
}

const CarInfo = ({vehicle, device, latestTrip}) => {

    const calendarStrings = {
        lastDay : '[Gisteren om] LT',
        sameDay : '[Vandaag om] LT',
        nextDay : '[Morgen om] LT',
        lastWeek: '[afgelopen] dddd [om] LT',
        nextWeek: 'dddd [om] LT',
        sameElse: 'L'
    };


    return (
        <div className="container">
            <label>Brandstof</label>
            <div id="activeBorder" className="active-border" style={getPercentageStyles(vehicle['lastFuelLevel'])}>
                <div id="circle" className="circle">
                    <span className="prec 360" id="prec">{getPercentage(vehicle['lastFuelLevel'])}</span>
                </div>
            </div>
            <label>KM stand</label>
            <div className="simple-info" id="odometer">
                {
                    new Intl.NumberFormat('nl-NL', {}).format(Math.round(vehicle['odometer'] / 1000))
                } km
            </div>
            <label>Locatie</label>
            <div className="simple-info" id="location">
                {device['address']['moniker']}
            </div>
            <label>Laatste trip</label>
            <div className="simple-info" id="latestTrip">
                {latestTrip['startAddress']['display']}&nbsp;&rarr;&nbsp;{latestTrip['endAddress']['display']}
                <br/>
                <br/>
                Afstand:
                <b>{new Intl.NumberFormat('nl-NL', {maximumSignificantDigits: 3}).format(latestTrip['distance'] / 1000)}km</b>
                <br/>Verbruik:
                <b>{latestTrip['fuelConsumption']}</b>
                <br/>Gemid. snelheid:
                <b>{latestTrip['averageSpeed']}</b>
            </div>
            <label>Score</label>
            <div className="simple-info" id="score">
                <ul>
                    {
                        Object.entries(latestTrip['score']).map((key, i) => {
                            return (
                                <li key={i}>{key[0]}:
                                    <b>{key[1]}</b>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="last-sync-date">
                <Moment locale="nl" calendar={calendarStrings} date={vehicle['seedOdometerTimestamp']}/>
            </div>
        </div>
    );
}

export default CarInfo;
