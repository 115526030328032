import React, {Component} from 'react'
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import constants from "./constants";
import {isValidToken} from "./utils";

const CLIENT_ID = constants.GOOGLE_CLIENT_ID;

class GoogleButton extends Component {
    constructor(props) {
        super(props);

        this.accountToken    = props.accountToken;
        this.setAccountToken = props.setAccountToken;

        this.state = {
            isLogined  : isValidToken(this.accountToken),
            accessToken: this.accountToken
        };

        this.login               = this.login.bind(this);
        this.handleLoginFailure  = this.handleLoginFailure.bind(this);
        this.logout              = this.logout.bind(this);
        this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
    }

    login(response) {
        if (response.tokenId) {
            this.setState(state => ({
                isLogined  : true,
                accessToken: response.tokenId
            }));

            this.setAccountToken(response.tokenId)
        }
    }

    logout(response) {
        this.setState(state => ({
            isLogined  : false,
            accessToken: ''
        }));

        this.setAccountToken('')
    }

    handleLoginFailure(response) {
        //alert('Failed to log in')
    }

    handleLogoutFailure(response) {
        //alert('Failed to log out')
    }

    render() {
        return (
            <div>
                {this.state.isLogined ?
                    <GoogleLogout clientId={CLIENT_ID}
                                  buttonText='Logout'
                                  onLogoutSuccess={this.logout}
                                  onFailure={this.handleLogoutFailure}></GoogleLogout> :
                    <GoogleLogin clientId={CLIENT_ID}
                                 buttonText='Login'
                                 onSuccess={this.login}
                                 onFailure={this.handleLoginFailure}
                                 autoLoad={true}
                                 cookiePolicy={'single_host_origin'}
                                 responseType='code,token'/>
                }
            </div>
        )
    }
}

export default GoogleButton;
