import jwt_decode from "jwt-decode";
import constants from "./constants";

export const isValidToken = (token) => {
    let retValue = true;

    if (!token || token.length === 0) {
        return false;
    }

    try {
        let decodedJwtToken = jwt_decode(token);
        if (decodedJwtToken.iss !== 'accounts.google.com') {
            console.debug('json token invalid: not from expected issue-er')
            return false;
        }

        if (decodedJwtToken.aud !== constants.GOOGLE_CLIENT_ID) {
            console.debug('json token invalid: not for expected audience')
            return false;
        }

        if ((new Date()).getTime() / 1000 - decodedJwtToken.exp > 0) {
            console.debug('json token invalid: is expired')
            return false;

        }
    } catch (error) {
        //console.log(error);
        return false;
    }


    return retValue;
};


export const retrieveFromLocalStorage = (key) => {
    return localStorage.getItem(key);
}

export const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}
