import React from 'react';
import './App.scss';
import GoogleButton from './GoogleButton';
import {isValidToken, retrieveFromLocalStorage, saveToLocalStorage} from "./utils";
import CarInfo from "./CarInfo";
import Loader from "./Loader";


const App = () => {
    const [accountToken, setAccountToken] = React.useState(retrieveFromLocalStorage('token'));
    const [carInfo, setCarInfo]           = React.useState(null);

    React.useEffect(() => {

        if (isValidToken(accountToken)) {
            fetch('https://verbruik.onseigenhuis.nl/car/', {
                method : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body   : JSON.stringify({
                    accountToken: accountToken
                }),
            })
                .then(response => response.json(), () => {
                    alert('Geen toegang!')
                })
                .then(data => setCarInfo(data));
        } else {
            setCarInfo(null)
        }

        saveToLocalStorage('token', accountToken);
    }, [accountToken]);


    return (
        <div className="App">
            <div id="content">
                {carInfo !== null ? (<CarInfo {...carInfo} />) : (isValidToken(accountToken) ? (<Loader/>) : null)}
            </div>
            <div style={{marginTop: '10px', marginBottom: '10px'}}>
                <GoogleButton accountToken={accountToken} setAccountToken={setAccountToken}/>
            </div>
        </div>
    );
}

export default App;
